import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as CardImg } from "../../assets/imgs/fold2Card.svg";
import AnimatedTextButton from "../generic/AnimatedTextButton";
import PrimaryButton from "../generic/PrimaryButton";
import { Link } from "react-router-dom";
const Fold6 = () => {
  const linkStyle = {
    textDecoration: "none",
    width: "fit-content",
  };
  return (
    <FullPageContainer>
      <Container>
        <Line>
          <BlueBannerWrapper>
            <BannerItem>
              <BannerTitle>Try our free dynamic link generator</BannerTitle>
              <BannerDescription>
                Generate dynamic links directly from web - no registration
                needed.
              </BannerDescription>
              <Link to="/create" style={linkStyle}>
                <PrimaryButton
                  text={"Generate dynamic link "}
                  arrow
                  bgVariant={lightThemeColors.background}
                  styled={{ color: lightThemeColors.darkColor }}
                />
              </Link>
            </BannerItem>
            <BannerItemSmall>
              <BannerTitle>Ready to start?</BannerTitle>
              <BannerDescription>
                Create a free account - no card needed.
              </BannerDescription>
              <Link
                onClick={() =>
                  window.open("https://app.grovs.io/register", "_blank")
                }
                style={linkStyle}
              >
                <PrimaryButton
                  text={"Register an account"}
                  bgVariant={lightThemeColors.darkBannerBg}
                  arrow
                />
              </Link>
            </BannerItemSmall>
          </BlueBannerWrapper>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Fold6;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.background};
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 80px;
  gap: 40px;
  @media (max-width: 990px) {
    padding: 20px;
  }
`;

const Line = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const BlueBannerWrapper = styled.section`
  display: flex;
  background: ${lightThemeColors.primaryColor};
  border-radius: 10px;
  width: 100%;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

const BannerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px;
  border-right: 1px dashed ${lightThemeColors.cardTextColorDark};
  width: 100%;
  &:last-child {
    border: none;
  }
  @media (max-width: 990px) {
    border-bottom: 1px dashed ${lightThemeColors.cardTextColorDark};
    border-right: unset;
  }
`;
const BannerItemSmall = styled(BannerItem)`
  width: 50%;
  @media (max-width: 990px) {
    width: 100%;
  }
`;
const BannerTitle = styled.h1`
  color: ${lightThemeColors.background};
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  @media (max-width: 990px) {
    font-size: 24px;
  }
`;

const BannerDescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px;

  @media (max-width: 990px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
