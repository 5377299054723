import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";

const PricingFaq = () => {
  return (
    <Container>
      <LeftSide>
        <h1>Pricing</h1>
        <h1 style={{ color: lightThemeColors.primaryColor, fontWeight: "800" }}>
          FAQs
        </h1>
      </LeftSide>

      <RightSide>
        <section>
          <h1>What are Monthly Active Users (MUAs)?</h1>
          <p>
            We define a monthly active user as a unique device that connects to
            our servers. This includes unique visitors who access links
            generated by our platforms or users of a web or mobile app that
            integrates our SDKs. The first 50,000 monthly active users (MAUs)
            are free, regardless of your chosen plan. Additional users beyond
            this limit will incur charges according to the selected plan.
          </p>
          <p>
            {" "}
            Monthly active user counts reset at the beginning of each month. For
            example, if this month you have 48,000 MAUs and next month you have
            55,000 MAUs, you will be charged for the additional 7,000 MAUs in
            the second month. If in the third month your MAUs drop below 50,000
            again, no charges will apply.
          </p>
        </section>
        <section>
          <h1>What differentiates Free vs Scale Up?</h1>
          <p>
            When you're on the Free plan, there's a risk that your links may
            stop working if you exceed 50,000 monthly active users (MAUs) and do
            not have a payment card on file. In such cases, all generated links
            will display an error message, and functionalities like link
            attribution, metrics, deep-links, and generating them from the app
            will be unavailable.
          </p>
          <p>
            {" "}
            With the Scale Up plan, we mitigate this issue by only charging for
            additional MAUs at the end of each month. Moreover, you'll receive
            integration support to easily incorporate our SDKs into your app or
            transition from another solution
          </p>
        </section>
        <section>
          <h1>What differentiates Scale Up from Enterprise?</h1>
          <p>
            {" "}
            With an enterprise account, you receive customized integration
            tailored to your needs. This includes additional features such as
            your own instance of grovs, custom domains, priority and on-site
            support, and Single Sign-On (SSO) capabilities to manage access
            within your organization.
          </p>
          <p>
            {" "}
            Furthermore, instead of monthly billing, you have the flexibility to
            choose different billing intervals.
          </p>
          <p>
            {" "}
            Enterprise plans are available for applications with more than
            500,000 monthly active users (MUAs).
          </p>
        </section>
        <section>
          <h1>
            Do you offer discounts for non-profits or educational institutions?
          </h1>
          <p>Yes, we offer a 50% discount for eligible organizations.</p>
          <p>
            {" "}
            To apply, please contact us at{" "}
            <span
              onClick={() => (window.location = "mailto:support@grovs.io")}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              support@grovs.io
            </span>{" "}
            with your organization's details.{" "}
          </p>
        </section>
        <section>
          <h1>Have more questions about our pricing?</h1>
          <p>
            Write us an email with your questions at{" "}
            <span
              onClick={() => (window.location = "mailto:support@grovs.io")}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              support@grovs.io
            </span>
            , we’ll get back to you in less than 24h.
          </p>
        </section>
      </RightSide>
    </Container>
  );
};

export default PricingFaq;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  gap: 50px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
`;
const RightSide = styled(LeftSide)`
  gap: 60px;

  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    text-align: left;
  }
  p {
    color: rgba(48, 44, 44, 0.7);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 990px) {
    gap: 40px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    section {
      gap: 20px;
    }
  }
`;
