import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import { useNavigate } from "react-router-dom";
import PricingCarousel from "./PricingCarousel";

const PricingPlans = () => {
  const navigate = useNavigate();
  const handleGetStartedForFree = () => {
    window.location.href = "https://app.grovs.io/register";
  };
  const handleContactSales = () => {
    navigate("/sales");
  };

  return (
    <OuterWrapper>
      <Container>
        <TitleWrapper>
          <h1>Plans that work</h1>
          <h1 style={{ color: lightThemeColors.darkColor }}>for everyone</h1>
        </TitleWrapper>

        <PlanSection>
          <SubscriptionPlanCard
            plan={"free"}
            handleGetStartedForFree={handleGetStartedForFree}
          />
          <SubscriptionPlanCard
            plan={"scale_up"}
            handleGetStartedForFree={handleGetStartedForFree}
          />
          <SubscriptionPlanCard
            plan={"enterprise"}
            handleContactSales={handleContactSales}
          />
        </PlanSection>
        <CarouselWrapper>
          <PricingCarousel />
        </CarouselWrapper>
      </Container>
    </OuterWrapper>
  );
};

export default PricingPlans;
const Container = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
  }

  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
`;
const OuterWrapper = styled.div`
  background: rgba(246, 244, 242, 1);
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    color: ${lightThemeColors.primaryColor};
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;
  }
`;

const PlanSection = styled.section`
  display: flex;
  gap: 5px;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 990px) {
    display: none;
  }
`;

const CarouselWrapper = styled.div`
  display: none;
  gap: 5px;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 990px) {
    display: flex;
  }
`;
