import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import PrimaryButton from "../generic/PrimaryButton";
import copy from "copy-to-clipboard";
import { motion } from "framer-motion";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import {
  customSuccessNotification,
  showSuccessNotification,
} from "../../helpers/Notifications";

const GeneratedLink = ({
  generatedLink,
  qrCode,
  handleGEnerateNewDynamicLink,
  handleRegisterFreeAccount,
}) => {
  const newDlinkBtnStyle = {
    marginTop: "30px",
    background: lightThemeColors.background,
    color: lightThemeColors.darkColor,
    padding: "15px",
    width: "100%",
  };
  const registerBtnStyle = {
    background: lightThemeColors.darkColor,
    color: lightThemeColors.background,
    padding: "15px",
    width: "100%",
  };

  const handleDownloadPng = () => {
    var node = document.getElementById("qr-code");
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        let link = document.createElement("a");
        link.download = "qr-code.png";
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleDownloadSVG = () => {
    htmlToImage
      .toSvg(document.getElementById("qr-code"))
      .then(function (dataUrl) {
        let link = document.createElement("a");
        link.download = "qr-code.svg";
        link.href = dataUrl;
        link.click();
      });
  };
  const handleCopy = (link) => {
    copy(link);
    customSuccessNotification("Link copied to clipboard");
  };

  return (
    <Container>
      <Title>Your generated link</Title>
      <Content
        initial={{ height: 0 }}
        animate={{ height: "100%" }}
        exit={{ height: 0 }}
        transition={{ duration: 0.4 }}
      >
        <GeneratedLinkSection>
          <Group>
            <h1>URL</h1>
            <GeneratedLinkWrapper>
              <p>{generatedLink}</p>
              <CopyBtn onClick={() => handleCopy(generatedLink)}>Copy</CopyBtn>
            </GeneratedLinkWrapper>
            <p>
              Here is your generated link, which you can use without any
              limitations.
            </p>
          </Group>
          <Group>
            <h1>QR CODE</h1>
            <Line>
              <CodeWrapper id="qr-code">
                <QRCode size={100} value={generatedLink} />
              </CodeWrapper>
              <Group>
                <p>
                  This is a QR code of your generated dynamic link. When
                  scanned, this QR code will redirect the user appropriately
                  based on the platform they are using.
                </p>
                <Line>
                  <TextBtn onClick={() => handleDownloadSVG()}>
                    Download SVG
                  </TextBtn>
                  <TextBtn onClick={() => handleDownloadPng()}>
                    Download PNG
                  </TextBtn>
                </Line>
              </Group>
            </Line>
            <PrimaryButton
              onClick={handleGEnerateNewDynamicLink}
              text={"Generate new Dynamic Link"}
              styled={newDlinkBtnStyle}
            />
          </Group>
        </GeneratedLinkSection>
        <InfoContent>
          <div>
            <h1>
              <span>Analytics</span> and <span>custom URLs</span> for your
              links?
            </h1>
            <p>
              <span>Join grovs</span> to create links with custom URLs.
              Additionally, you can measure the impact of your links, analyze
              the number of opens, the platforms used, and how your links were
              utilized.
            </p>
          </div>

          <PrimaryButton
            onClick={handleRegisterFreeAccount}
            text={"Register a free grovs account"}
            styled={registerBtnStyle}
          />
        </InfoContent>
      </Content>
    </Container>
  );
};

export default GeneratedLink;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow: hidden;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: ${lightThemeColors.primaryColor};
`;

const Content = styled(motion.div)`
  display: flex;
  gap: 90px;
  align-items: center;
  overflow: hidden;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const GeneratedLinkSection = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: column;
  width: 100%;
  @media (max-width: 990px) {
    gap: 20px;
  }
`;
const InfoContent = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid rgba(238, 236, 235, 1);
  padding-left: 40px;
  height: fit-content;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 20px;
    span {
      font-weight: 800;
    }
  }
  p {
    color: ${lightThemeColors.textColor};
    font-size: 15px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    span {
      color: ${lightThemeColors.primaryColor};
    }
  }
  @media (max-width: 990px) {
    border-left: none;
    padding-left: 0;
  }
`;
const Group = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    color: ${lightThemeColors.textColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
`;
const GeneratedLinkWrapper = styled.div`
  display: flex;
  padding: 12px 20px;
  background: rgba(42, 134, 255, 0.2);
  border-radius: 6px;

  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
  }
`;
const CopyBtn = styled.button`
  color: ${lightThemeColors.primaryColor};
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  border-radius: 3px;
  background: ${lightThemeColors.background};
  margin-left: auto;
  border: none;
  padding: 2px 6px;
  cursor: pointer;
`;

const Line = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const TextBtn = styled.button`
  color: ${lightThemeColors.darkColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  background: none;
  border: none;
  cursor: pointer;
`;
const CodeWrapper = styled.div`
  min-width: 100px;
  min-height: 100px;
  background: white;
  display: flex;
  align-items: center;
`;
