import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../assets/style/colors";

const PrivacyPolicyPage = () => {
  return (
    <Container>
      <LeftSide>
        <h1>Privacy Policy</h1>
      </LeftSide>

      <RightSide>
        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Grovs's Privacy Policy. This policy outlines how we
            collect, use, disclose, and protect your information when you use
            our services ("Services").
          </p>

          <h2>2. Information We Collect</h2>
          <p>
            We collect information from you when you interact with our Services.
            This may include:
            <ul>
              <li>
                Personal information such as your name, email address, and
                contact details provided when you register or communicate with
                us.
              </li>
              <li>
                Usage information such as your IP address, browser type, and
                pages visited, collected automatically when you use our website
                or mobile applications.
              </li>
              <li>
                Cookies and similar technologies to enhance your user experience
                and track usage patterns.
              </li>
              <li>
                Information from third-party sources where permitted by law,
                such as social media platforms or advertising networks.
              </li>
            </ul>
          </p>

          <h2>3. Use of Information</h2>
          <p>
            We use the information we collect for various purposes, including:
            <ul>
              <li>Providing and maintaining our Services.</li>
              <li>
                Communicating with you, including responding to your inquiries
                and providing customer support.
              </li>
              <li>Analyzing usage trends and improving our Services.</li>
              <li>
                Customizing your experience and delivering relevant content and
                advertisements.
              </li>
              <li>
                Complying with legal obligations and protecting our rights.
              </li>
            </ul>
          </p>

          <h2>4. Sharing of Information</h2>
          <p>
            We may share your information with third parties in the following
            circumstances:
            <ul>
              <li>
                With service providers and partners who assist us in operating
                our Services, conducting our business, or servicing you.
              </li>
              <li>
                With your consent or at your direction, such as when you choose
                to share information with third-party applications or platforms.
              </li>
              <li>
                When required by law or to protect our legal rights, such as in
                response to a subpoena or similar legal process.
              </li>
              <li>
                In connection with a merger, acquisition, or sale of all or a
                portion of our assets.
              </li>
            </ul>
          </p>

          <h2>5. Your Choices</h2>
          <p>
            You have choices regarding the collection, use, and sharing of your
            information:
            <ul>
              <li>
                You can access and update your personal information by logging
                into your account settings.
              </li>
              <li>
                You may opt-out of receiving promotional communications from us
                by following the instructions in those communications.
              </li>
              <li>
                You can disable cookies through your browser settings, although
                this may impact your ability to use certain features of our
                Services.
              </li>
            </ul>
            Please note that opting out of certain data processing activities
            may limit your access to certain features of the Services.
          </p>

          <h2>6. Data Security</h2>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or electronic storage is 100% secure.
            Therefore, while we strive to use commercially acceptable means to
            protect your information, we cannot guarantee its absolute security.
          </p>

          <h2>7. Children's Privacy</h2>
          <p>
            Our Services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If we learn that we have inadvertently collected personal
            information from a child under 13, we will take steps to delete that
            information as soon as possible.
          </p>

          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices and legal requirements. We will notify you
            of any material changes by posting the updated policy on our website
            or through other appropriate means. Your continued use of the
            Services after such changes constitutes your acceptance of the
            updated Privacy Policy.
          </p>

          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at : support@grovs.io.
          </p>
        </section>
      </RightSide>
    </Container>
  );
};

export default PrivacyPolicyPage;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
    flex-direction: column;
  }
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 10px;
    margin-left: 30px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
`;
const RightSide = styled(LeftSide)`
  gap: 60px;

  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
  p {
    color: rgba(48, 44, 44, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 990px) {
    gap: 40px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    section {
      gap: 20px;
    }
  }
`;
