import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import InputFieldWithValidation from "../generic/InputFieldWithValidation";

import { ReactComponent as DownIcon } from "../../assets/icons/downMenu.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/Settings.svg";
import { useExpanded } from "../../hooks/useExpanded";
import { CustomSwitch } from "../generic/CustomSwitch";
import IosConfig from "./IosConfig";
import AndroidConfig from "./AndroidConfig";
import DesktopConfig from "./DesktopConfig";
import PrimaryButton from "../generic/PrimaryButton";
import { ReactComponent as CheckIcon } from "../../assets/icons/GreenCheck.svg";
import { AnimatePresence, motion } from "framer-motion";

const AdvancedItem = ({ text, checked, setChecked }) => {
  return (
    <AdvancedItemWrapper>
      <p>{text}</p>
      <CustomSwitch checked={checked} setChecked={setChecked} />
    </AdvancedItemWrapper>
  );
};

const RedirectItem = ({ text }) => {
  return (
    <RedirectItemWrapper>
      <CheckIcon />
      <p>{text}</p>
    </RedirectItemWrapper>
  );
};

const ConfigureRedirects = ({
  nonMobileDevices,
  setNonMobileDevices,
  tablets,
  setTablets,
  iosPhone,
  setIosPhone,
  androidPhone,
  setAndroidPhone,
  iosTablet,
  setIosTablet,
  androidTablet,
  setAndroidTablet,
  desktop,
  setDesktop,
  mac,
  setMac,
  linux,
  setLinux,
  handleContinue,
  validInputs,
}) => {
  const [expandex, expand] = useExpanded();

  const REDIRECTS_LIST = [
    "iOS",
    "Android",
    "Web",
    "Phone and Tablets",
    "MacOS, Windows, Linux",
  ];
  return (
    <Container>
      <Content
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "100%", opacity: 1 }}
        exit={{ height: 0 }}
        transition={{ duration: 0.4 }}
      >
        <InputWrapper>
          <TitleWrapper>
            <Title>ConfigureRedirects</Title>
            <Border>
              <BorderFill />
            </Border>
          </TitleWrapper>
          <IosConfig
            tablets={tablets}
            phone={iosPhone}
            setPhone={setIosPhone}
            tablet={iosTablet}
            setTablet={setIosTablet}
          />
          <AndroidConfig
            tablets={tablets}
            phone={androidPhone}
            setPhone={setAndroidPhone}
            tablet={androidTablet}
            setTablet={setAndroidTablet}
          />
          <DesktopConfig
            nonMobile={nonMobileDevices}
            desktop={desktop}
            mac={mac}
            linux={linux}
            setDesktop={setDesktop}
            setLinux={setLinux}
            setMac={setMac}
          />

          <AdvancedSettingsWrapper onClick={() => expand()}>
            <Line>
              <SettingsIcon />
              <p> Advanced settings</p>
            </Line>
            <DownIcon style={!expandex ? { rotate: "180deg" } : {}} />
          </AdvancedSettingsWrapper>
          <AnimatePresence>
            {expandex && (
              <AdvancedSettingsList
                initial={{ height: 0 }}
                animate={{ height: "60px" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.4 }}
              >
                <AdvancedItem
                  text={"Different redirect on tablets"}
                  checked={tablets}
                  setChecked={setTablets}
                />
                <AdvancedItem
                  text={"Different redirect on non-mobile devices"}
                  checked={nonMobileDevices}
                  setChecked={setNonMobileDevices}
                />
              </AdvancedSettingsList>
            )}
          </AnimatePresence>
          <PrimaryButton
            disabled={!validInputs}
            onClick={handleContinue}
            text={"Continue"}
            styled={{
              marginTop: "30px",
              background: lightThemeColors.darkColor,
              color: lightThemeColors.background,
              width: "100%",
              padding: "15px",
            }}
          />
        </InputWrapper>

        <InfoContent>
          <InfoTitle>
            Generate your free dynamic link with{" "}
            <span>no registration needed.</span>
          </InfoTitle>
          <InfoSubtitle>
            With <span>grovs</span> you can create intelligent dynamic links
            that seamlessly redirect users to your chosen websites across
            various platforms and device types.
          </InfoSubtitle>
          <ListTitle>Supported redirects</ListTitle>
          <InfoList>
            {REDIRECTS_LIST.map((item, index) => (
              <RedirectItem key={index} text={item} />
            ))}
          </InfoList>
        </InfoContent>
      </Content>
    </Container>
  );
};

export default ConfigureRedirects;
const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow: hidden;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  color: ${lightThemeColors.primaryColor};
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 13px;
`;
const Border = styled.div`
  width: 100%;
  min-height: 3px;
  background: ${lightThemeColors.primaryColorFaded};
  border-radius: 1px;
`;
const BorderFill = styled.div`
  width: 50%;
  background: ${lightThemeColors.primaryColor};
  min-height: 3px;
  border-radius: 4px;
`;
const Content = styled(motion.div)`
  display: flex;
  gap: 90px;
  align-items: center;
  overflow: hidden;
  @media (max-width: 990px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  gap: 17px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 990px) {
    gap: 10px;
  }
`;
const AdvancedSettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const AdvancedSettingsList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 13px;
  overflow: hidden;
`;
const AdvancedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 14px;
    font-weight: 500;
  }
`;
const InfoContent = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid rgba(238, 236, 235, 1);
  padding-left: 40px;
  height: fit-content;
  @media (max-width: 990px) {
    padding-left: 0;
    border-left: none;
  }
`;
const InfoTitle = styled.h1`
  color: ${lightThemeColors.darkColor};
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  span {
    font-weight: 800;
  }
`;
const InfoSubtitle = styled.p`
  color: ${lightThemeColors.inputFieldLabelColor};
  font-size: 15px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  span {
    color: ${lightThemeColors.primaryColor};
  }
`;
const ListTitle = styled.p`
  color: ${lightThemeColors.darkColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
`;
const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 990px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const RedirectItemWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: ${lightThemeColors.darkColor};
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;
